<template>
  <v-app>
    <div class="text-center ma-5" v-if="loading === true">
      <v-progress-circular :size="70" :width="10" color="blue" indeterminate />
      <br />
      お客様の情報を確認中です。<br />
      処理が完了するまでお待ちください。
    </div>

    <v-alert
      v-model="alert"
      close-text="Close Alert"
      color="orange accent-4"
      dark
      dismissible
      class="ma-5"
    >
      {{ error_message }}
    </v-alert>
  </v-app>
</template>

<script>
import { Firebase } from "../config/firebase";

export default {
  name: "PatientRegster",

  data() {
    return {
      loading: true,
      showcamera: false,
      is_go_priscription: false,
      regist: false,
      user_name: "",
      welcome: null,
      alert: false,
      error_message: "",
      patient: null,
      patient_id: "",
      clinic: null,
      user: null,
    };
  },
  computed: {
    clinic_id() {
      return this.$route.params.id;
    },
    user_id() {
      return this.$store.getters.user.uid;
    },
  },

  created: async function () {
    await this.fetchUsers();

    await this.fetchClinics();
    await this.fetchPatients();

    await this.checkPatients();
  },
  methods: {
    fetchUsers: async function () {
      const userRef = await Firebase.firestore()
        .collection("Users")
        .doc(this.$store.getters.user.uid);
      await userRef.get().then((doc) => {
        if (doc.exists) {
          this.user = doc.data();
          this.user.id = doc.id;
        } else {
          console.log("No user document!");
        }
        this.error = true;
      });
    },
    fetchClinics: async function () {
      const clinicRef = await Firebase.firestore()
        .collection("Clinics")
        .doc(this.clinic_id);
      await clinicRef.get().then((doc) => {
        if (doc.exists) {
          this.clinic = doc.data();
          this.clinic.id = doc.id;
        } else {
          console.log("No clinic document!");
        }
        this.error = true;
      });
    },
    fetchPatients: async function () {
      await Firebase.firestore()
        .collection("Patients")
        .where("clinic_id", "==", this.clinic.id)
        .where("phoneNumber", "==", this.user.phone)
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              this.patient = doc.data();
              this.patient.id = doc.id;
            });
          }
        });
    },

    checkPatients: async function () {
      const clinics = this.user.clinics;
      let is_registed = false;
      for (let i = 0; i < clinics.length; i++) {
        if (clinics[i].clinic_id == this.clinic_id) {
          is_registed = true;
          console.log("patient:", this.patient);
          console.log("Register clinicsは登録済み", clinics[i].id);
          break;
        }
      }
      if (is_registed == true) {
        this.$router.push({
          name: "priscription_list",
          params: { id: this.clinic.id },
          query: {
            patientNumber: this.patient.patientNumber,
            patient_id: this.patient.id,
          },
        });
      } else {
        const clinic = {
          clinic_id: this.clinic.id,
          clinic_name: this.clinic.name,
          patient_id: this.patient.id,
          patient_name: this.patient.name,
          patientNumber: this.patient.patientNumber,
        };
        this.user.clinics.push(clinic);
        await this.update_user_clinic();
      }
    },
    update_user_clinic: async function () {
      const userRef = await Firebase.firestore()
        .collection("Users")
        .doc(this.$store.getters.user.uid);
      await userRef.update(this.user);
    },

    async addPatientCode(patient) {
      let patient_id = "";
      this.patient = patient;
      this.alert = false;
      patient_id = await Firebase.firestore()
        .collection("Patients")
        .where("clinic_id", "==", this.clinic_id)
        .where("patientNumber", "==", patient.patientNumber)
        .where("user_id", "==", this.user_id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.patient_id = doc.id;
            this.error_message = "すでに登録済みです。";
            this.loading = false;
            this.alert = true;
            this.is_go_priscription = true;
          });
          return patient_id;
        })
        .catch((error) => {
          throw new Error(
            Date().toLocaleString("ja") +
              " [erro code: 121] Patients: get error" +
              error
          );
        });
      if (this.patient_id != "") {
        console.log("this.patient_id ", this.patient_id);
        return;
      }

      // Create patient document
      const patientRef = Firebase.firestore().collection("Patients");
      const user_clinic = await patientRef
        .add(patient)
        .then((doc) => {
          this.patient.id = doc.id;
          const user_clinic = {
            clinic_id: this.clinic.id,
            clinic_name: this.clinic.name,
            patient_id: doc.id,
            patientNumber: patient.patientNumber,
            patient_name: patient.name,
          };
          return user_clinic;
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
      // Add clinic and patient under User clinics array
      await Firebase.firestore()
        .collection("Users")
        .doc(this.user_id)
        .update({
          clinics: Firebase.firestore.FieldValue.arrayUnion(user_clinic),
        })
        .then(() => {
          console.log("User document update");
          this.patient_id = this.patient.id;
          this.go_priscription_list();
          return;
        });
      return;
    },
    go_priscription_list() {
      this.$router.push({
        name: "priscription_list",
        params: { id: this.clinic_id },
        query: {
          patientNumber: this.patient.patientNumber,
          patient_id: this.patient_id,
        },
      });
    },
  },
};
</script>

<style>
#canvas {
  display: none;
}
#video {
  border: 1px solid #333;
  background: #f2f2f2;
  width: 70%;
}
#image {
  border: 1px solid #333;
  background: #f2f2f2;
}
</style>
